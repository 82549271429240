<template>
    <div>
        <div class="mask" v-show="isShow" @click="closeLightbox"></div>
        <form
            class="end-service-lightbox"
            v-show="isShow"
        >
            <button
                type="button"
                class="btn-close"
                @click="closeLightbox"
            ></button>
            <div class="lightbox-header">
                <div class="title">結束服務</div>
            </div>
            <div class="lightbox-body">
                <div class="content">   
                    <BaseInput
                        title="芳療師班數"
                        placeholder="請輸入"
                        type="text"
                        class="form-control"
                        name="member_class_num"
                    />

                    <BaseRadio
                        class="me-3"
                        type="radio"
                        name="is_refund"
                        label="不退款"
                        value="0"
                    />
                    <BaseRadio
                        type="radio"
                        name="is_refund"
                        label="退款"
                        value="1"
                    />
 
                </div>
            </div>
            <div class="lightbox-footer">
                <button
                    type="button"
                    class="btn"
                    @click="handleSave"
                >確認</button>
            </div>
        </form>
    </div>
</template>

<script>
import { reactive, ref, computed, watch } from "vue";
import { useStore } from "vuex";
import { useForm, ErrorMessage } from "vee-validate";
import * as yup from "yup";
import { basePost } from "@/js/services/baseService";
import SwalCustom from "@/hooks/common/useSwalCustom";

export default {
    name: "EndServiceLightbox",
    components: {
        ErrorMessage,
    },
    props: {
        isShow: Boolean,
        orderId: Number
    },
    emits: ["update:isShow"],
    setup(props, { emit }) {
        const isShow = computed(() => props.isShow);
        const orderId = computed(() => props.orderId);
        const { state, getters, dispatch } = useStore();
        const storeId = computed(() => state.auth.store_id).value;
        // form驗證設定
        const validationSchema = yup.object().shape({
            member_class_num: yup.number().typeError('請輸入班數').required("請輸入班數"),
        });
        const { values, handleSubmit, isSubmitting, setValues, setFieldValue, resetForm } = useForm({
            initialValues: {
                is_refund: '1'
            },
            validateOnMount: false,
            validationSchema,
        });

        const customSwal = new SwalCustom({
            title: "錯誤!",
            text: "",
            icon: "error",
            confirmButtonText: "確定",
        });


        const handleSave = handleSubmit(async (values, actions) => {
            const {
                is_refund,
                member_class_num
            } = values;

            try {
                const res = await basePost("/api_order/end_of_service", {
                    order_id: orderId.value,
                    is_refund,
                    member_class_num: member_class_num || 0
                });
                const { status, data } = res;

                if (status === 200 && data.res_code === 1) {
                    closeLightbox();
                    customSwal.fire({
                        title: "成功!",
                        icon: "success",
                        text: "結束服務成功",
                    });
                } else {
                    customSwal.fire({
                        text: data.res_content,
                    });
                }
            } catch (err) {
                console.error(err);
                customSwal.fire({
                    text: err.message,
                });
            }
        });

        function closeLightbox () {
            emit("update:isShow", false)
        }

        watch([ isShow, orderId ], (arr) => {
            if (orderId.value >= 0 && isShow.value) {
            } else {
                resetForm()
            }
        });

        return {
            closeLightbox,
            handleSave,
            isSubmitting,
            values,
        };
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "~@/css/mixins";
@import "~@/css/grid";

.end-service-lightbox {
    width: 100%;
    max-width: 680px;
    border-radius: 10px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 9999;
    box-shadow: 0 8px 21px 0 rgba(0, 0, 0, 0.51);
    background-color: #fff;
}

.btn-close {
    @include size(28px);
    border-radius: 50%;
    border: 2px solid #fff;
    box-shadow: 0 6px 25px 0 rgba(0, 0, 0, 0.3);
    background-color: rgba(0, 0, 0, 1);
    background-image: unset;
    position: absolute;
    right: -10px;
    top: -10px;
    z-index: 10;
    opacity: 1;
    box-sizing: border-box;

    &::before,
    &::after {
        content: "";
        display: inline-block;
        @include size(14px, 2px);
        background-color: #fff;
        position: absolute;
        left: 50%;
        top: 50%;
    }

    &::before {
        transform: translate(-50%, -50%) rotate(45deg);
    }

    &::after {
        transform: translate(-50%, -50%) rotate(-45deg);
    }
}

.lightbox-header {
    .title {
        font-size: 22px;
        font-weight: bold;
        text-align: center;
        padding: 40px 0 20px;
        border-bottom: 1px solid rgba(112, 112, 112, .2);
    }
}

.lightbox-body {
    padding: 40px 0 20px;
  .content {
    width: 360px;
    margin: 0 auto;

    label {
        margin-bottom: 10px;
        margin-right: 10px;
        input[type="radio"] {
            display: none;
        }
        input[type="radio"] + span {
            display: inline-block;
            padding-left: 38px;
            line-height: 28px;
            background: url("~@/assets/tgd/lightbox/radio-none.svg")
                no-repeat;
            user-select: none;
        }
        input[type="radio"]:checked + span {
            background: url("~@/assets/tgd/lightbox/radio-checked.svg")
                no-repeat;
            color: #a4641a;
        }
        span {
            font-size: 16px;
        }
    }

  }
}

.lightbox-footer {
    padding: 20px 33px 31px 33px;
    transition: all 0.3s ease;
    text-align: center;
}

.btn {
    display: inline-block;
    margin: 0 auto;
    width: 300px;
    border-radius: 11px;
    padding: 15px;
    text-align: center;
    background: transparent
        linear-gradient(
            102deg,
            #ab7e2b 0%,
            #f1e3b5 14%,
            #ab7e2b 64%,
            #c48109 100%,
        );
    box-shadow: 0px 3px 6px #00000029;
    color: #fff;
    border: 1px solid #ffffff85;
    p {
        width: 19.6px;
    }
}
</style>
