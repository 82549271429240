<template>
    <div>
        <div class="mask" v-show="isShow" @click="closeLightbox"></div>
        <form
            class="divide-class-lightbox"
            v-show="isShow && isDataReady"
        >
            <button
                type="button"
                class="btn-close"
                @click="closeLightbox"
            ></button>
            <div class="lightbox-header">
                <div class="title">總班數: {{ values.order_class_num }}</div>
            </div>
            <div class="lightbox-body">
                <div class="content">   
                     <BaseInput
                        title="主要芳療師的班數"
                        placeholder="請輸入"
                        type="text"
                        class="form-control"
                        name="member_class_num"
                    />
                    <div 
                        v-if="!waitingList.length"
                        style="height: 53px; color: #a4641a;"
                        class="fw-bold d-flex align-items-center justify-content-center mb-3"
                    >
                        目前無可接手芳療師
                    </div>
                    <BaseSelect
                        v-else
                        title=""
                        :options="waitingList"
                        name="divide_class_member_id"
                        placeholder="選擇接手芳療師"
                    />
                     <BaseInput
                        title="分班芳療師班數"
                        placeholder="請輸入"
                        type="text"
                        class="form-control"
                        name="divide_class_num"
                    />
                </div>
            </div>
            <div class="lightbox-footer">
                <button
                    v-if="!isSet && waitingList.length"
                    type="button"
                    class="btn"
                    @click="handleSave"
                >確認</button>
            </div>
        </form>
    </div>
</template>

<script>
import { reactive, ref, computed, watch } from "vue";
import { useStore } from "vuex";
import { useForm, ErrorMessage } from "vee-validate";
import * as yup from "yup";
import moment from "moment";
import { basePost } from "@/js/services/baseService";
import SwalCustom from "@/hooks/common/useSwalCustom";
import { set } from "lodash";

export default {
    name: "DivideClassLightbox",
    components: {
        ErrorMessage,
    },
    props: {
        isShow: Boolean,
        orderId: Number
    },
    emits: ["update:isShow"],
    setup(props, { emit }) {
        const isShow = computed(() => props.isShow,);
        const orderId = computed(() => props.orderId,);
        const { state, getters, dispatch } = useStore();
        const storeId = computed(() => state.auth.store_id).value;
        const isSet = ref(false);
        // form驗證設定
        const validationSchema = yup.object().shape({
            divide_class_member_id: yup.number().typeError('必填').min(1, "必填"),
            member_class_num: yup.number().typeError('請輸入班數').required("請輸入班數"),
            divide_class_num: yup.number().typeError('請輸入班數').required("請輸入班數")
        });
        const { values, handleSubmit, isSubmitting, setValues, setFieldValue, resetForm } = useForm({
            validateOnMount: false,
            validationSchema,
            initialValues: {
                divide_class_member_id: 0,
                order_class_num: 0,
                member_class_num: 0,
                divide_class_num: 0,
            }
        });

        const customSwal = new SwalCustom({
            title: "錯誤!",
            text: "",
            icon: "error",
            confirmButtonText: "確定",
        });


        // 取得資料
        const getCheckOutData = async () => {
            const res = await basePost("/api_order/order_details", {
                store_id: storeId,
                order_id: orderId.value,
            });
            const { status, data } = res;
            if (status === 200 && data.res_code === 1) {
                if(data.res_data.answer_list.divide_class_member_id > 0) {
                    isSet.value = true
                }
                return data.res_data;
            }
        };


        const memberWorkList = reactive({
                data: { waiting_list: [] },
        });
        const waitingList = computed(()=>{
            if(!memberWorkList.data.waiting_list) return [];
            return memberWorkList.data.waiting_list.map(item => {
                return {
                    id: item.member_id,
                    name: item.member_name
                }
            })
        })
        const getMemberWorkStatus = async () => {
            try {
                const res = await basePost("/api_room/member_condition", {
                    store_id: storeId,
                });
                const { status, data } = res;

                if (status === 200 && data.res_code === 1) {
                    const { res_data } = data;
                    memberWorkList.data = res_data;
                }
            } catch (err) {
                console.error(err);
            }
        };
        
        const isDataReady = ref(false)
        watch([ isShow, orderId ], async (arr) => {
            if (orderId.value >= 0 && isShow.value) {
                await getMemberWorkStatus();
                await getCheckOutData().then((data) => {
                    const {
                        divide_class_member_id, 
                        order_class_num,
                        member_class_num,
                        divide_class_num
                    } = data.answer_list;
                    resetForm({ 
                        values: { 
                            divide_class_member_id,
                            order_class_num,
                            member_class_num,
                            divide_class_num,
                        }
                    });
                    isDataReady.value = true
                });
            } else {
                resetForm();
                isSet.value = false;
                isDataReady.value = false
            }
        });


        const handleSave = handleSubmit(async (values, actions) => {

            const {
                divide_class_member_id, 
                order_class_num,
                member_class_num,
                divide_class_num
            } = values;

            try {
                const res = await basePost("/api_order/divide_class_member_number", {
                    order_id: orderId.value,
                    divide_class_member_id, 
                    member_class_num: member_class_num || 0,
                    divide_class_num: divide_class_num || 0
                });
                const { status, data } = res;

                if (status === 200 && data.res_code === 1) {
                    closeLightbox();

                    customSwal.fire({
                        title: "成功!",
                        icon: "success",
                        text: "分班成功",
                    });
                } else {
                    customSwal.fire({
                        text: data.res_content,
                    });
                }
            } catch (err) {
                console.error(err);
                customSwal.fire({
                    text: err.message,
                });
            }
        });

        function closeLightbox () {
            emit("update:isShow", false)
        }


        return {
            closeLightbox,
            handleSave,
            isSubmitting,
            values,
            waitingList,
            isSet,
            isDataReady
        };
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "~@/css/mixins";
@import "~@/css/grid";

.divide-class-lightbox {
    width: 100%;
    max-width: 680px;
    border-radius: 10px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 9999;
    box-shadow: 0 8px 21px 0 rgba(0, 0, 0, 0.51);
    background-color: #fff
}

.btn-close {
    @include size(28px);
    border-radius: 50%;
    border: 2px solid #fff;
    box-shadow: 0 6px 25px 0 rgba(0, 0, 0, 0.3);
    background-color: rgba(0, 0, 0, 1);
    background-image: unset;
    position: absolute;
    right: -10px;
    top: -10px;
    z-index: 10;
    opacity: 1;
    box-sizing: border-box;

    &::before,
    &::after {
        content: "";
        display: inline-block;
        @include size(14px, 2px);
        background-color: #fff;
        position: absolute;
        left: 50%;
        top: 50%;
    }

    &::before {
        transform: translate(-50%, -50%) rotate(45deg);
    }

    &::after {
        transform: translate(-50%, -50%) rotate(-45deg);
    }
}

.lightbox-header {
    .title {
        font-size: 22px;
        font-weight: bold;
        text-align: center;
        padding: 40px 0 20px;
        border-bottom: 1px solid rgba(112, 112, 112, .2);
    }
}
.lightbox-body {
    padding: 40px 0 20px;
  .content {
    width: 320px;
    margin: 0 auto;
    p {
        color:#A4641A;
        font-size: 21px;
        font-weight: bold;
    }
  }
}

.lightbox-footer {
    padding: 10px 33px 31px 33px;
    transition: all 0.3s ease;
    text-align: center;
}

.btn {
    display: inline-block;
    margin: 0 auto;
    width: 300px;
    border-radius: 11px;
    padding: 15px;
    text-align: center;
    background: transparent
        linear-gradient(
            102deg,
            #ab7e2b 0%,
            #f1e3b5 14%,
            #ab7e2b 64%,
            #c48109 100%,
        );
    box-shadow: 0px 3px 6px #00000029;
    color: #fff;
    border: 1px solid #ffffff85;
    p {
        width: 19.6px;
    }
}
</style>
